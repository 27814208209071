export default {
	login: {
		loginInTitle: 'User Login',
		loginAccount: 'Please input Account',
		loginPWD: 'Please input Password',
		code: 'Captcha',
		rememberMe: 'Remember me',
		AccountError: 'Please input a Right Account',
		PWError: 'Please input a password',
		codeError: 'Please input a Captcha',
		login: 'Login',
	},
	user:{
		wellcome:'hello',
		center:'user center',
		logout:'logout',
	},
	personalCenter:{
		userSettings:"User Settings",
		name:"name",
		mobile:"mobile",
		email:"email",
		gender:"gender",
		genderinfo:{
			n:"male",
			v:"female",
			w:"unknown"
		},
		update:"update",
		reset:"reset",
		passSettings:"Password Settings",
		oldpassword:"old password",
		newpassword1:"new password",
		newpassword2:"confirm password",
		commit:"submit"
	},
	btn:{
		del:'Delete',
		edit:'Edition',
		add:'Add',
		reset:'Reset',
		search:'Search',
		cardSearch:'Card Search',
		detail:'Detail',
		oprate:'Operate',
		fullscreen:'Fullscreen',
		nomal:'Normal',
		disable:'Disable',
		choose:'please choose',
		nodata:'No Data',
		cancel:'Cancel',
		confirm:'Confirm',
		save:'Save',
		keyWord:'keyWord',
		status:'Status',
	},
	adminManage:{
		name0:'Index',
		name1:'Username',
		name2:'Account',
		name3:'Permission Key',
		name4:'Status',
		name5:'Create Time',
		name6:'Password',
		name7:'roles',
		name8:'dept',
		lastlogin:'Last Login',
		mobile:'Phone Number',
		email:'Email',
		gender:'Gender',
		gj:'Nationality'
	},
	userManage:{
		name0:'index',
		name1:'Picture',
		name2:'Name',
		name3:'Phone Number',
		name31:'Link Card',
		name32:'Link Card',
		name33:'Change Link',
		name34:'Set Password',
		name341:'Set Password',
		name35:'Set',
		name36:'ID',
		name37:'Expiry',
		name38:'D.O.B',
		name39:'Age',
		name301:'Nationality',
		name302:'Passport NO.',
		name303:'OccupatIon',
		name304:'Working Years',
		name305:'Salary',
		name306:'Email',
		name307:'Password',
		name4:'Administrator',
		name41:'Card Number',
		name42:'Total Balance',
		name43:'Investments',
		name44:'Returns',
		name45:'Situation',
		name46:'Create Time',
		name47:'Edit Card',
		name48:'Add Account',
		name49:'Clear Account',
		name50:'Delete',
		name51:'Add Card',
		name52:'Send date',
		name53:'Description',
		name54:'Clear Amount',
		name5:'status',
		name3P:'please enter',
		name1P:'please enter',
		name5P:'please choice',
		name6:'start time',
		name7:'to',
		name8:'end time',
		tuijian:'Reference',
		yzsamount:"Profit Part",
		dzsamount:"Remaining Part",
	},
	jfActivityManage:{
		name1:'Front Cover',
		name2:'Title',
		name3:'Creat Time',
		name4:'sort',
		name5:'status',
		name6:'please choice',
		name7:'please input',
		name8:'Text',
	},
	jfJifenManage:{
		name1:'Time',
		name2:'Phone Number',
		name3:'Total Investments',
		name4:'Total Returns',
		name5:'Name',
		name6:'Administrator ',
		name7:'Card Number',
		name8:'Type',
		name9:'Account',
		name10:'Investments',
		name11:'Returns',
		name12:'Send date',
		name13:'Create Time',
		totalDuration:'Total Duration',
		zhouqi:'CYC',
		Days:'Days',
		zizhks:'StartTime of Account',
		zizhjz:'TerminalTime of Account',
		hykcj:'CreatTime of Card'
	},
	menuManage:{
		name0:'index',
		name1:'menu name',
		name2:'icon',
		name3:'sort',
		name4:'route',
		name5:'permission',
		name6:'left visible',
		name7:'status',
		name8:'parent menu',
		namebtn:'Button config',
		name9:"Create button permission"
	},
	roleManage:{
		name0:'index',
		name1:'role name',
		name2:'Key',
		name3:'is admin',
		name4:'status',
		name5:'sort',
		namebtn:"Permission manage"
	},
	authorityManage:{
		roleName:"Selected",
		nodata:"not have",
		save:"Save",
		dataAuthorization:"Data authorization",
		authDescription:"The data range that authorized users can operate on",
		menuAuthorization:"Menu authorization",
		menuDescription:"Authorize users to operate within the menu",
		chooseRole:"Please select a role first",
		datalevel0:"Only personal data permission",
		datalevel1:"Data permissions of this department",
		datalevel2:"Data permissions for this department and below",
		datalevel3:"All data permissions",
		datalevel4:"Custom Data Permissions",
	},
}