<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="580px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="Rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-form-item label="Card Password" prop="card_pass">
                    <el-input type="password" v-model="formData.card_pass" maxlength="6" v-limitPositiveNumber @keyup.enter="submitCardSearch"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitCardSearch" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
    </div>
</template>

<script>
    import {deepClone,generateCardNo} from "@/utils/util";
    import {UsersUsersAdd,UsersUsersEdit} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import { ElNotification } from 'element-plus'
    export default {
        name: "addModuleDeleteVerify",
        components: {LyDialog},
        emits: ['refreshData','closed'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                rowItem:"",
                formData:{
                    card_pass:""
                },
                Rules:{
                    card_pass: [
                        {required: true, message: 'Required',trigger: 'blur'}
                    ],
                },
                cardReader:null,
                icdev: 0, //设备句柄
                snra:null, //typeA返回的序列号
                snra2:null, //typeA二次防冲突返回的序列号
                snrb:null, //typeB返回的序列号
                counter:null,
                icode2Uid:null,
                uhfEpc:null,
                is_usb_connected:false,
            }
        },
        unmounted() {
            // this.disconnectReader()
            // this.cardReader = null
        },
        mounted(){
            // //回调, ret 包含三个属性, functionId, result 及 resultData.
            // //functionId 为函数 id (READER_CMD 中的属性), 确定返回的是哪个命令执行的结果
            // //result 为执行结果, 0 成功, 其它失败,
            // //当 result 为 0 时 resultData 为返回结果, 当 result 不为 0 时, resultData 为错误信息
            // this.cardReader = getReader()
            // var vm = this
            // this.cardReader.onResult(function (ret) {
            //     // console.log(ret)
            //     //执行成功
            //     if (ret.result == 0) {
            //         switch (ret.functionId) {
            //             case READER_CMD._reader_server_connect:
            //                 // vm.$message.success('cardReader 读写器ws服务连接成功');
            //                 ElNotification({
            //                     title: 'Service notification',
            //                     message: "Card reader WebSocket service connected successfully",
            //                     position: 'top-right',
            //                     type: 'success',
            //                   })
            //                 vm.connectUsbReader()
            //                 break;
            //             case READER_CMD._reader_cmd_connect:
            //                 vm.icdev = parseInt(ret.resultData); //连接成功后, resultData 为设备句柄
            //                 if(!vm.is_usb_connected){
            //                     ElNotification({
            //                         title: 'Card reader prompt',
            //                         message: "cardReader USB card reader connected successfully",
            //                         position: 'top-right',
            //                         type: 'success',
            //                     })
            //                     // vm.$message.success('cardReader usb读写器连接成功')
            //                     vm.is_usb_connected = true
            //                 }
            //                 break;
            //             case READER_CMD._reader_cmd_disconnect:
            //                 // vm.$message.warning('cardReader usb读写器断开连接成功')
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read:
            //                 vm.handleClose()
            //                 vm.$emit('refreshData',ret.resultData.substr(0,15))
            //                 // msg.value = msg.value + "SLE4442 卡读数据成功. 读取到的数据: " + ret.resultData + "\n";
            //                 break;
            //             case READER_CMD._reader_cmd_4442_write:
            //                 // vm.$message.success("卡写数据成功")
            //                 ElNotification({
            //                     title: 'card notification',
            //                     message: "card write success",
            //                     position: 'top-right',
            //                     type: 'success',
            //                 })
            //                 break;
            //             case READER_CMD._reader_cmd_4442_verify_sc:
            //                 vm.sle4442Read()
            //                 // ElNotification({
            //                 //     title: 'card notification',
            //                 //     message: "card verify passwrod success",
            //                 //     position: 'top-right',
            //                 //     type: 'success',
            //                 // })
            //                 // vm.$message.success("卡验证密码成功")
            //                 break;
            //             case READER_CMD._reader_cmd_4442_change_sc:
            //                 vm.$message.success("card change passwrod success")
            //                 vm.formData.card_pass = deepClone(vm.formDataPass.newCardPass)
            //                 vm.handleResetPassClose()
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_sc:
            //                 vm.$message.success("SLE4442 卡读取密码成功. 读取到的密码: " + ret.resultData)
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_counter:
            //                 vm.$message.success("SLE4442 卡读取密码错误计数成功. 密码错误计数为: " + parseInt(ret.resultData))
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_pro_bit:
            //                 vm.$message.success("SLE4442 卡读保护位成功. 读取到保护位数据: " + ret.resultData)
            //                 break;
            //             case READER_CMD._reader_cmd_4442_protect:
            //                 vm.$message.success("SLE4442 卡保护数据成功")
            //                 break;
            //         }
            //     }else {
            //         //失败打印错误信息
            //         if (ret.resultData != "" && ret.resultData != null && ret.resultData != undefined &&!!ret.resultData){
            //             if(ret.resultData === "初始化通信端口失败。"){
            //                 vm.$message.warning("Failed to initialize communication port")
            //             }else if(ret.resultData == "密码错误。"){
            //                 // vm.sle4442Read()
            //                 vm.$message.warning("password verify error")
            //             }else if(ret.resultData === "无效的句柄。"){
            //                 vm.$message.warning("invalid handle")
            //             }
            //             else{
            //                 vm.$message.warning(ret.resultData)
            //             }
            //         }
            //         // console.log(ret)
            //     }
            // })
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.formData = {
                    card_pass:""
                }
                this.rowItem = ""
                this.is_usb_connected = false
                this.disconnectReader()
                this.cardReader.CloseWS()
                this.cardReader = null
                this.$emit('closed')
            },
            submitCardSearch() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        if(this.formData.card_pass.length != 6){
                            this.$message.warning("password error")
                            return
                        }
                        //this.sle4442ReadCounter()
                        // this.sle4442Read()
                        this.sle4442VerifySC(this.formData.card_pass)
                    }
                })
            },
            sle4442ReadCounter(){
                try {
                    this.cardReader.sle4442ReadCounter(this.icdev);
                } catch (e) {
                    let stre = JSON.stringify(e)
                    if(stre.indexOf("createSocket is not defined") != -1 || !!stre||!(!!e.Message)){
                        this.$message.warning("Card Server not connected.")
                    }else{
                        this.$message.warning(e.Message)
                    }
                }
            },
            sle4442VerifySC(pass) {
                try {
                    this.cardReader.sle4442VerifySC(this.icdev, pass);
                } catch (e) {
                    let stre = JSON.stringify(e)
                    if(stre.indexOf("createSocket is not defined") != -1 || !!stre||!(!!e.Message)){
                        this.$message.warning("Card Server not connected.")
                    }else{
                        this.$message.warning(e.Message)
                    }
                }
            },
            sle4442Read() {
                try {
                    this.cardReader.sle4442Read(this.icdev, 32, 224);
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
            checkResetPassword(rule, value, callback) {
                if (!value) {
                    return callback('password required')
                }
                if (value.length < 6) {
                    return callback('Password should not be less than 6 digits')
                }
                if (value.length > 6) {
                    return callback('Password not greater than 6 digits')
                }
                var numReg = /^[0-9]*$/
                var numRe = new RegExp(numReg)
                if (!numRe.test(value)) {
                    return callback('Must be a number from 0 to 9')
                }
                return callback()
            },
            //断开连接的读写器
            disconnectReader() {
                try {
                    this.cardReader.disconnect(this.icdev);
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                this.rowItem = deepClone(item)
                //回调, ret 包含三个属性, functionId, result 及 resultData.
                //functionId 为函数 id (READER_CMD 中的属性), 确定返回的是哪个命令执行的结果
                //result 为执行结果, 0 成功, 其它失败,
                //当 result 为 0 时 resultData 为返回结果, 当 result 不为 0 时, resultData 为错误信息
                this.cardReader = getReader()
                var vm = this
                this.cardReader.onResult(function (ret) {
                    // console.log(ret)
                    //执行成功
                    if (ret.result == 0) {
                        switch (ret.functionId) {
                            case READER_CMD._reader_server_connect:
                                // vm.$message.success('cardReader 读写器ws服务连接成功');
                                ElNotification({
                                    title: 'Service notification',
                                    message: "Card reader WebSocket service connected successfully",
                                    position: 'top-right',
                                    type: 'success',
                                })
                                vm.connectUsbReader()
                                break;
                            case READER_CMD._reader_cmd_connect:
                                vm.icdev = parseInt(ret.resultData); //连接成功后, resultData 为设备句柄
                                if(!vm.is_usb_connected){
                                    ElNotification({
                                        title: 'Card reader prompt',
                                        message: "cardReader USB card reader connected successfully",
                                        position: 'top-right',
                                        type: 'success',
                                    })
                                    // vm.$message.success('cardReader usb读写器连接成功')
                                    vm.is_usb_connected = true
                                }
                                break;
                            case READER_CMD._reader_cmd_disconnect:
                                // vm.$message.warning('cardReader usb读写器断开连接成功')
                                break;
                            case READER_CMD._reader_cmd_4442_read:
                                vm.$emit('refreshData',vm.rowItem,ret.resultData.substr(0,16))
                                vm.handleClose()
                                // msg.value = msg.value + "SLE4442 卡读数据成功. 读取到的数据: " + ret.resultData + "\n";
                                break;
                            case READER_CMD._reader_cmd_4442_write:
                                // vm.$message.success("卡写数据成功")
                                ElNotification({
                                    title: 'card notification',
                                    message: "card write success",
                                    position: 'top-right',
                                    type: 'success',
                                })
                                break;
                            case READER_CMD._reader_cmd_4442_verify_sc:
                                vm.sle4442Read()
                                // ElNotification({
                                //     title: 'card notification',
                                //     message: "card verify passwrod success",
                                //     position: 'top-right',
                                //     type: 'success',
                                // })
                                // vm.$message.success("卡验证密码成功")
                                break;
                            case READER_CMD._reader_cmd_4442_change_sc:
                                vm.$message.success("card change passwrod success")
                                vm.formData.card_pass = deepClone(vm.formDataPass.newCardPass)
                                vm.handleResetPassClose()
                                break;
                            case READER_CMD._reader_cmd_4442_read_sc:
                                vm.$message.success("SLE4442 卡读取密码成功. 读取到的密码: " + ret.resultData)
                                break;
                            case READER_CMD._reader_cmd_4442_read_counter:
                                vm.$message.success("SLE4442 卡读取密码错误计数成功. 密码错误计数为: " + parseInt(ret.resultData))
                                break;
                            case READER_CMD._reader_cmd_4442_read_pro_bit:
                                vm.$message.success("SLE4442 卡读保护位成功. 读取到保护位数据: " + ret.resultData)
                                break;
                            case READER_CMD._reader_cmd_4442_protect:
                                vm.$message.success("SLE4442 卡保护数据成功")
                                break;
                        }
                    }else {
                        //失败打印错误信息
                        if (ret.resultData != "" && ret.resultData != null && ret.resultData != undefined &&!!ret.resultData){
                            if(ret.resultData === "初始化通信端口失败。"){
                                vm.$message.warning("Failed to initialize communication port")
                            }else if(ret.resultData == "密码错误。"){
                                // vm.sle4442Read()
                                vm.$message.warning("password verify error")
                            }else if(ret.resultData === "无效的句柄。"){
                                vm.$message.warning("invalid handle")
                            }else if(ret.resultData === "设备未连接。"){
                                vm.$message.warning("card devices not connected")
                            }
                            else{
                                vm.$message.warning(ret.resultData)
                            }
                        }
                        // console.log(ret)
                    }
                })
            },
            //连接USB读写器
            connectUsbReader() {
                try {
                   this.cardReader.connectUsb(); //连接usb设备
                } catch (e) {
                    let stre = JSON.stringify(e)
                    if(stre.indexOf("createSocket is not defined") != -1 || !!stre||!(!!e.Message)){
                        this.$message.warning("Card Server not connected.")
                    }else{
                        this.$message.warning(e.Message)
                    }
                }
            },
            //更改卡密码
            sle4442ChangeSC(pass) {
                try {
                    this.cardReader.sle4442ChangeSC(this.icdev, pass);
                    // this.formData.card_pass = pass
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

