<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name49')" prop="id">
                            <el-select v-model="formData.id" :placeholder="$t('userManage.name5P')" clearable style="width: 100%" @change="handleChange">
                                <el-option
                                        v-for="item in userList"
                                        :key="item.id"
                                        :label="'Account'+item.acount_index"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name54')" prop="qc_amount">
                            <el-input v-model="formData.qc_amount" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="cardVerify" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
        <AddModuleCardSearch ref="cardSearchFlag" @refreshData="getcardno" v-if="cardSearchDialogShow" @closed="cardSearchDialogShow=false"></AddModuleCardSearch>
    </div>
</template>

<script>
    import {deepClone} from "@/utils/util";
    import {jifenappJifenClear,jifenappJifen} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import AddModuleCardSearch from "./addModuleCardSearch";
    export default {
        name: "clearZiAcount",
        components: {LyDialog,AddModuleCardSearch},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                card_no:"",
                formData:{
                    id:"",
                    qc_amount:'',
                },
                rules:{
                    id: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                    qc_amount: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                },
                userList:[],
                cardSearchDialogShow:false,
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.card_no = ""
                this.formData = {
                    id:"",
                    qc_amount:'',
                }
            },
            handleChange(e){
                if(e){
                    let newdata = this.userList.filter(item=>item.id==e)[0]
                    this.formData.qc_amount = Number(newdata.give_amount) + Number(newdata.recharge_amount)
                }
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.getjifenappJifen(item.id)
                }
                this.card_no = item.card_no
            },
            getcardno(cardno){
                if(cardno === this.card_no){
                    this.submitData()
                }else{
                    this.$message.warning("Card Number Error")
                }
            },
            cardVerify(){
                this.cardSearchDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.cardSearchFlag.addUserFn(null,"Card Verify")
                })
            },
            getjifenappJifen(id){
                jifenappJifen({type:3,user:id}).then(res=>{
                    if(res.code === 2000){
                        this.userList = res.data.data
                    }
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        jifenappJifenClear(param).then(res=>{
                            this.loadingSave=false
                            if(res.code ==2000) {
                                this.$message.success(res.msg)
                                this.handleClose()
                                this.$emit('refreshData')
                            } else {
                                this.$message.warning(res.msg)
                            }
                        })

                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

