<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item :label="$t('jfJifenManage.name2')">
                    <el-input v-model.trim="formInline.mobile" maxlength="60"  clearable :placeholder="$t('jfJifenManage.name2')" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('jfJifenManage.name7')">
                    <el-input v-model.trim="formInline.card_no" maxlength="60"  clearable :placeholder="$t('jfJifenManage.name7')" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('jfJifenManage.name5')">
                    <el-input v-model.trim="formInline.name" maxlength="60"  clearable :placeholder="$t('jfJifenManage.name5')" @change="search" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('jfJifenManage.name1')">
                    <el-date-picker
                            style="width:350px"
                            v-model="timers"
                            type="datetimerange"
                            @change="timeChange"
                            :range-separator="$t('userManage.name7')"
                            :start-placeholder="$t('userManage.name6')"
                            :end-placeholder="$t('userManage.name8')">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission('jfJifenManage','Search')">{{$t('btn.search')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">{{$t('btn.reset')}}</el-button></el-form-item>
            </el-form>
        </div>
        <ul class="order-static" ref="orderStatic">
            <li>{{$t('jfJifenManage.name3')}}：￥{{orderstatics.totalcz}}</li>
            <li>{{$t('jfJifenManage.name4')}}：￥{{orderstatics.totalxf}}</li>
        </ul>
        <el-table :height="tableHeight" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
<!--            <el-table-column type="index" width="60" align="center" label="序号">-->
<!--                <template #default="scope">-->
<!--                    <span v-text="getIndex(scope.$index)"></span>-->
<!--                </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column min-width="70" prop="avatar" label="用户头像">-->
<!--                    <template #default="scope">-->
<!--                        <img  :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" style="width: 30px;height: 30px" :onerror="defaultImg">-->
<!--                    </template>-->
<!--            </el-table-column>-->
            <el-table-column min-width="110" prop="userinfo.name" :label="$t('jfJifenManage.name5')"></el-table-column>
            <el-table-column min-width="140" prop="userinfo.mobile" :label="$t('jfJifenManage.name2')"></el-table-column>
            <el-table-column min-width="60" prop="userinfo.age" :label="$t('userManage.name39')"></el-table-column>
            <el-table-column min-width="90" prop="userinfo.gender" :label="$t('adminManage.gender')"></el-table-column>
            <el-table-column min-width="120" prop="userinfo.gj" :label="$t('userManage.name301')"></el-table-column>
            <el-table-column min-width="150" prop="userinfo.saleser_name" :label="$t('jfJifenManage.name6')"></el-table-column>
            <el-table-column min-width="150" prop="userinfo.tuijian" :label="$t('userManage.tuijian')"></el-table-column>
            <!-- <el-table-column min-width="180" prop="userinfo.card_no" :label="$t('jfJifenManage.name7')"></el-table-column> -->
            <el-table-column min-width="120" prop="type_name" :label="$t('jfJifenManage.name8')"></el-table-column>
            <el-table-column min-width="120" :label="$t('jfJifenManage.name9')">
                <template #default="scope">
                    {{"Account"+scope.row.acount_index}}
                </template>
            </el-table-column>
            <el-table-column min-width="120" :label="$t('userManage.name42')">
                <template #default="scope">
                    {{(parseFloat(scope.row.recharge_amount) + parseFloat(scope.row.gived_amount)).toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column min-width="150" prop="recharge_amount" :label="$t('jfJifenManage.name10')"></el-table-column>
            <el-table-column min-width="150" prop="give_amount" :label="$t('jfJifenManage.name11')"></el-table-column>
            <el-table-column min-width="150" prop="gived_amount" :label="$t('userManage.yzsamount')"></el-table-column>
            <el-table-column min-width="150" :label="$t('userManage.dzsamount')">
                <template #default="scope">
                    {{(parseFloat(scope.row.give_amount)- parseFloat(scope.row.gived_amount)).toFixed(2) }}
                </template>
            </el-table-column>
            <!-- <el-table-column min-width="150" prop="give_time" :label="$t('jfJifenManage.name12')"></el-table-column> -->
            <el-table-column min-width="180" prop="userinfo.create_datetime" :label="$t('jfJifenManage.hykcj')"></el-table-column>
            <el-table-column min-width="180" prop="create_datetime" :label="$t('jfJifenManage.zizhks')"></el-table-column>
            <el-table-column min-width="220" prop="zizhjz" :label="$t('jfJifenManage.zizhjz')"></el-table-column>
            <el-table-column min-width="130" prop="total_times" :label="$t('jfJifenManage.totalDuration')"></el-table-column>
            <el-table-column min-width="70" prop="period" :label="$t('jfJifenManage.zhouqi')" fixed="right"></el-table-column>
        </el-table>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination";
    import {dateFormats} from "@/utils/util";
    import {jifenappJifen,jifenappJifenStatic} from '@/api/api'
    import { lyMixins } from "@/mixins/mixins"
    export default {
        mixins: [lyMixins],
        components:{
            Pagination,
        },
        name:'jfJifenManage',
        data() {
            return {
                isFull:false,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                orderstatics:{
                    totalcz: 0,
                    totalxf:0,
                },
                defaultImg:"this.src='"+require('../../assets/img/avatar.jpg')+"'",
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                tableData:[],
                timers:[],
            }
        },
        methods:{
            setFull(){
                this.isFull=!this.isFull
            },
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            handleEdit(row,flag) {
                let vm = this
                if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.timers = []
                    this.search()
                }
            },

            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
                this.getOrderstatistics()
            },
            //订单金额统计获取
            getOrderstatistics(){
                jifenappJifenStatic(this.formInline).then(res => {
                     if(res.code ==2000) {
                         this.orderstatics = res.data.data
                     }
                 })
                  //   .finally(() => {
                  //  this.$nextTick(() => {
                  //     this.$refs.tableref.doLayout();
                  //   });
                  // });
            },
            //获取列表
            async getData(){
                this.loadingPage = true
                 jifenappJifen(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFile(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportData() {
                // var params = {
                //     type:3,
                //     page: 1,
                //     limit: 9999,
                // }
                // retrieveFinancestatisticsExport(params).then(res => {
                //      if(res.code ==2000) {
                //          this.downloadFile(res.data.data)
                //          //this.$message.warning(res.data.data)
                //      }
                //  })
            }
        },
        created() {
            this.getData()
            this.getOrderstatistics()
        },
    }
</script>
<style lang="scss">
    .order-static{
        display: flex;
        font-size: 14px;
        background: var(--l-order-statics);
        padding: 10px;
        li{
            margin-right: 30px;

        }
    }
</style>