export default {
	login: {
		loginInTitle: '用户登录',
		loginAccount: '请输入账号',
		loginPWD: '请输入密码',
		code: '验证码',
		rememberMe: '记住密码',
		AccountError: '请输入用户名',
		PWError: '请输入密码',
		codeError: '请输入验证码',
		login: '登录',
	},
	user:{
		wellcome:'你好',
		center:'个人中心',
		logout:'退出登录',
	},
	personalCenter:{
		userSettings:"用户设置",
		name:"姓名",
		mobile:"用户电话",
		email:"邮箱",
		gender:"性别",
		genderinfo:{
			n:"男",
			v:"女",
			w:"未知"
		},
		update:"更新",
		reset:"重置",
		passSettings:"密码设置",
		oldpassword:"原密码",
		newpassword1:"新密码",
		newpassword2:"确认密码",
		commit:"提交"
	},
	btn:{
		del:'删除',
		edit:'编辑',
		add:'添加',
		reset:'重置',
		search:'搜索',
		cardSearch:'插卡查询',
		detail:'详情',
		oprate:'操作',
		fullscreen:'全屏',
		nomal:'正常',
		disable:'禁用',
		choose:'请选择',
		nodata:'暂无数据',
		cancel:'取消',
		confirm:'确认',
		save:'保存',
		keyWord:'关键字',
		status:'状态',
	},
	adminManage:{
		name0:'序号',
		name1:'姓名',
		name2:'账号',
		name3:'权限字符',
		name4:'账号状态',
		name5:'创建时间',
		name6:'登录密码',
		name7:'角色',
		name8:'部门',
		lastlogin:'最近一次登录',
		mobile:'手机号',
		email:'邮箱',
		gender:'性别',
		gj:'国籍',
	},
	userManage:{
		name0:'序号',
		name1:'头像',
		name2:'姓名',
		name3:'手机号',
		name31:'绑定会员卡',
		name32:'绑定',
		name33:'更换绑定',
		name34:'会员卡密码',
		name341:'修改卡密码',
		name35:'设置',
		name36:'身份证',
		name37:'身份证有效期',
		name38:'出生年月',
		name39:'年龄',
		name301:'国籍',
		name302:'护照号码',
		name303:'职工',
		name304:'工作年限',
		name305:'月薪',
		name306:'邮箱',
		name307:'密码',
		name4:'使用者',
		name41:'会员卡',
		name42:'总余额',
		name43:'充值金额',
		name44:'赠送金额',
		name45:'账号状态',
		name46:'创建时间',
		name47:'编辑会员卡',
		name48:'添加子账户',
		name49:'清除子账户',
		name50:'删除会员卡',
		name51:'添加会员卡',
		name52:'赠送日期',
		name53:'套餐描述',
		name54:'清除金额',
		name5:'状态',
		name3P:'请输入用户名称',
		name1P:'请输入会员ID',
		name5P:'请选择',
		name6:'开始日期',
		name7:'至',
		name8:'结束日期',
		tuijian:'推荐人姓名',
		yzsamount:"已赠送金额",
		dzsamount:"待赠送金额",
	},
	jfActivityManage:{
		name1:'封面图',
		name2:'标题名称',
		name3:'创建时间',
		name4:'排序',
		name5:'状态',
		name6:'请选择',
		name7:'请输入',
		name8:'正文',
	},
	jfJifenManage:{
		name1:'日期',
		name2:'手机号',
		name3:'总充值金额',
		name4:'总消费金额',
		name5:'姓名',
		name6:'使用者',
		name7:'会员卡号',
		name8:'类型',
		name9:'账户',
		name10:'充值金额',
		name11:'赠送金额',
		name12:'赠送日期',
		name13:'充值时间',
		totalDuration:'总时长',
		zhouqi:'周期',
		Days:'天数',
		zizhks:'子账户开始时间',
		zizhjz:'子账户截止时间',
		hykcj:'会员卡号创建时间'
	},
	menuManage:{
		name0:'序号',
		name1:'菜单名称',
		name2:'图标',
		name3:'排序',
		name4:'路由地址',
		name5:'权限',
		name6:'侧边可见',
		name7:'状态',
		name8:'父级菜单',
		namebtn:'按钮配置',
		name9:"创建按钮权限"
	},
	roleManage:{
		name0:'序号',
		name1:'角色名称',
		name2:'权限字符',
		name3:'是否管理员',
		name4:'状态',
		name5:'排序',
		namebtn:"权限管理"
	},
	authorityManage:{
		roleName:"当前选择",
		nodata:"无",
		save:"保存",
		dataAuthorization:"数据授权",
		authDescription:"授权用户可操作的数据范围",
		menuAuthorization:"菜单授权",
		menuDescription:"授权用户在菜单中可操作的范围",
		chooseRole:"请先选择角色",
		datalevel0:"仅本人数据权限",
		datalevel1:"本部门数据权限",
		datalevel2:"本部门及以下数据权限",
		datalevel3:"全部数据权限",
		datalevel4:"自定义数据权限",
	},

}