<template>
    <div>
        <ly-dialog v-model="dialogVisible" :title="loadingTitle" width="560px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name43')" prop="recharge_amount">
                            <el-input v-model="formData.recharge_amount"></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="24">
                        <el-form-item :label="$t('userManage.name52')" prop="give_time">
                             <el-date-picker v-model="formData.give_time" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%" />
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name53')" prop="tc_desc">
                            <el-input v-model="formData.tc_desc"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name44')" prop="give_amount">
                            <el-input v-model="formData.give_amount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('jfJifenManage.zhouqi')" prop="period">
                            <el-select size="default" v-model="formData.period" :placeholder="$t('userManage.name5P')" clearable style="width: 100%" @change="calctotal">
                                <el-option
                                        v-for="item in periodList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('jfJifenManage.Days')" prop="day">
                            <el-select size="default" v-model="formData.day" :placeholder="$t('userManage.name5P')" clearable style="width: 100%" @change="calctotal">
                                <el-option
                                        v-for="item in dayList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item :label="$t('jfJifenManage.totalDuration')" prop="total_times">
                            <el-input v-model="formData.total_times" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="cardVerify" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
        <AddModuleCardSearch ref="cardSearchFlag" @refreshData="getcardno" v-if="cardSearchDialogShow" @closed="cardSearchDialogShow=false"></AddModuleCardSearch>
    </div>
</template>

<script>
    import {deepClone} from "@/utils/util";
    import {jifenappJifenAdd} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import AddModuleCardSearch from "./addModuleCardSearch";
    export default {
        name: "addZiAcount",
        components: {LyDialog,AddModuleCardSearch},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/avatar.jpg'),
                card_no:"",
                formData:{
                    user:"",
                    recharge_amount:'',
                    give_time:'',
                    tc_desc:'',
                    mobile:'',
                    give_amount:"",
                    period:"",
                    day:"",
                    total_times:"",
                },
                periodList:[
                    {id:1,name:1},
                    {id:2,name:2},
                    {id:3,name:3},
                    {id:4,name:4},
                    {id:5,name:5},
                    {id:6,name:6},
                    {id:7,name:7},
                    {id:8,name:8},
                    {id:9,name:9},
                    {id:10,name:10},
                    {id:11,name:11},
                    {id:12,name:12},
                ],
                dayList:[
                    {id:1,name:1},
                    {id:5,name:5},
                    {id:10,name:10},
                    {id:20,name:20},
                    {id:30,name:30},
                ],
                rules:{
                    recharge_amount: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                    // give_time: [
                    //     {required: true, message: 'Must input',trigger: 'blur'}
                    // ],
                    tc_desc: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                    give_amount: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                    period: [
                        {required: true, message: 'Must select',trigger: 'blur'}
                    ],
                    day: [
                        {required: true, message: 'Must select',trigger: 'blur'}
                    ],
                    total_times: [
                        {required: true, message: 'Must input',trigger: 'blur'}
                    ],
                },
                cardSearchDialogShow:false,
            }
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.card_no = ""
                this.formData = {
                    user:"",
                    recharge_amount:'',
                    give_time:'',
                    tc_desc:'',
                    mobile:'',
                    give_amount:"",
                    period:"",
                    day:"",
                    total_times:"",
                }
            },
            calctotal(e){
                if(!!this.formData.period && !!this.formData.day){
                    this.formData.total_times = this.formData.period * this.formData.day
                }
                
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    this.formData.user = item.id
                }
                this.card_no = item.card_no
            },
            getcardno(cardno){
                if(cardno === this.card_no){
                    this.submitData()
                }else{
                    this.$message.warning("Card Number Error")
                }
            },
            cardVerify(){
                this.cardSearchDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.cardSearchFlag.addUserFn(null,"Card Verify")
                })
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        this.loadingSave=true
                        let param = {
                            ...this.formData
                        }
                        if(this.formData.user){
                            jifenappJifenAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

