<template>
    <div>
        <ly-dialog v-model="dialogVisible" :fullscreen="false" :title="loadingTitle" width="50%" top="40px" :before-close="handleClose">
            <el-form :inline="false" :model="formData" :rules="rules" ref="rulesForm" label-position="right" label-width="auto">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item :label="$t('userManage.name4')" prop="saleser">
                            <el-select v-model="formData.saleser" :disabled="true" :placeholder="$t('userManage.name5P')" clearable style="width: 100%">
                                <el-option
                                        v-for="item in adminList"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <!-- <el-form-item :label="$t('userManage.name31')" prop="card_no"> -->
                        <el-form-item prop="card_no">
                            <template #label>
                                <span class="table-operate-btn" @click="openBindCardDialog">{{$t('userManage.name32')}}</span>
                            </template>
                            <span>{{customCardNo}}</span>
                            <el-input ref="cardInput" :disabled="true" v-model="formData.card_no" @input="formatCardNumber" :maxlength="16"></el-input>
                            <!-- <span class="table-operate-btn" @click="openBindCardDialog">{{$t('userManage.name32')}}</span> -->
                            <!-- <span class="table-operate-btn" @click="openBindCardDialog">{{$t('userManage.name32')}}/{{$t('userManage.name33')}}</span> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item :label="$t('userManage.name34')" prop="card_pass"> -->
                        <el-form-item prop="card_pass">
                            <template #label>
                                <span class="table-operate-btn" @click="openRestPassDialog">{{$t('userManage.name341')}}</span>
                            </template>
                            <el-input v-model="formData.card_pass" type="password" maxlength="6" :disabled="true"></el-input>
                            <!-- <span class="table-operate-btn" @click="openRestPassDialog">{{$t('userManage.name341')}}</span> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name1')" prop="avatar">
                            <el-upload
                                    class="avatar-uploader"
                                    action=""
                                    :show-file-list="false"
                                    :disabled="true"
                                    ref="uploadDefaultImage"
                                    :http-request="imgUploadRequest"
                                    :on-success="imgUploadSuccess"
                                    :before-upload="imgBeforeUpload">
                                    <el-image class="avatar" :src="!!formData.avatar?formData.avatar:defaultImg" :preview-src-list="[!!formData.avatar?formData.avatar:defaultImg]"  :preview-teleported="true" :onerror="defaultImg" ></el-image>
                                <!-- <el-image class="avatar" v-if="formData.avatar" :src="formData.avatar" :preview-src-list="[formData.avatar]"  :preview-teleported="true" :onerror="defaultImg" ></el-image> -->
                                <!-- <el-icon v-else class="avatar-uploader-icon" size="medium"><Plus /></el-icon> -->
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name2')"  prop="name">
                            <el-input v-model="formData.name"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('adminManage.gender')" prop="gender">
                            <el-radio-group v-model="formData.gender">
                                <el-radio :label="1">male</el-radio>
                                <el-radio :label="2">female</el-radio>
                                <el-radio :label="0">unknown</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.tuijian')" prop="tuijian">
                            <el-input v-model="formData.tuijian"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name36')" prop="idcard_no">
                            <el-input v-model="formData.idcard_no"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name37')" prop="idcard_validity">
                            <el-input v-model="formData.idcard_validity"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name38')" prop="birthday">
                            <el-date-picker v-model="formData.birthday" type="date" format="YYYY-MM-DD" value-format="YYYY-MM-DD" style="width: 100%" @change="calcAge"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name39')" prop="age" class="is-required">
                            <el-input v-model="formData.age" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name301')" prop="gj">
                            <el-input v-model="formData.gj"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name302')" prop="passport">
                            <el-input v-model="formData.passport"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name303')" prop="workers">
                            <el-input v-model="formData.workers"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name304')" prop="worker_life">
                            <el-input v-model="formData.worker_life"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name305')" prop="wages">
                            <el-input v-model="formData.wages"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name306')" prop="email">
                            <el-input v-model="formData.email"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name3')" prop="mobile">
                            <el-input v-model="formData.mobile"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item :label="$t('userManage.name307')" prop="password">
                            <el-input type="password" v-model="formData.password"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
<!--                <el-form-item label="状态：" prop="is_active">-->
<!--                    <el-switch-->
<!--                        v-model="formData.is_active"-->
<!--                        active-color="#13ce66"-->
<!--                        inactive-color="#ff4949">-->
<!--                    </el-switch>-->
<!--                </el-form-item>-->
            </el-form>
            <template #footer>
                <el-button @click="handleClose" :loading="loadingSave">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingSave">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
        <ly-dialog v-model="dialogBindCardVisible" :title="$t('userManage.name31')" width="560px" :before-close="handleBindCardClose">
            <el-form :inline="false" :model="formDataBindCard" :rules="bindCardRules" ref="rulesFormBindCard" label-position="right" label-width="auto">
                <!-- <el-alert title="The default password for new cards is 'ffffff'" type="warning" /> -->
                <el-form-item :label="$t('userManage.name34')" prop="card_pass" v-show="false">
                    <el-input type="password" v-model="formDataBindCard.card_pass" maxlength="6"></el-input>
                </el-form-item>
                <el-form-item :label="$t('userManage.name31')" prop="card_no">
                    <el-input v-model="formDataBindCard.card_no" ref="cardLinkNewInput" @input="formatCardNumberLinkNew" :maxlength="16"></el-input>
                    <span>{{customLinkNewCardNo}}</span>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleBindCardClose">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitBindCard">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
        <ly-dialog v-model="dialogSetPassVisible" :title="$t('userManage.name341')" width="400px" :before-close="handleResetPassClose">
            <el-form :inline="false" :model="formDataPass" :rules="passwordRules" ref="rulesFormResetPass" label-position="right" label-width="auto">
                <el-form-item :label="$t('userManage.name34')" prop="newCardPass">
                    <el-input type="password" v-model="formDataPass.newCardPass" maxlength="6" placeholder="6-digit password"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button @click="handleResetPassClose">{{$t('btn.cancel')}}</el-button>
                <el-button type="primary" @click="submitResetPassword">{{$t('btn.confirm')}}</el-button>
            </template>
        </ly-dialog>
        <AddModuleCardSearch ref="cardSearchFlag" @refreshData="getcardno" v-if="cardSearchDialogShow" @closed="cardSearchDialogShow=false"></AddModuleCardSearch>
    </div>
</template>

<script>
    import {deepClone,generateCardNo} from "@/utils/util";
    import {UsersUsersAdd,UsersUsersEdit,platformsettingsUploadPlatformImg,apiSystemUser} from "@/api/api";
    import LyDialog from "@/components/dialog/dialog";
    import { ElNotification } from 'element-plus'
    import {getStorage} from '@/utils/util'
    import AddModuleCardSearch from "./addModuleCardSearch";
    export default {
        name: "addUser",
        components: {LyDialog,AddModuleCardSearch},
        emits: ['refreshData'],
        data() {
            return {
                dialogVisible:false,
                loadingSave:false,
                loadingTitle:'',
                defaultImg:require('../../../assets/img/agentavatar.png'),
                customCardNo:"",
                customLinkNewCardNo:"",
                formData:{
                    saleser:'',
                    username:'',
                    nickname:'',
                    mobile:'',
                    is_active:true,
                    avatar:'',
                    gender:0,
                },
                formDataBindCard:{
                    card_no:"",
                    card_pass:"ffffff"
                },
                bindCardRules:{
                    card_no: [
                        {required: true, message: 'Required',trigger: 'blur'}
                    ],
                    card_pass: [
                        {required: true, message: 'Required',trigger: 'blur'}
                    ],
                },
                formDataPass:{
                    newCardPass:"123456",
                },
                passwordRules:{
                    newCardPass: [
                        {required: true, validator: this.checkResetPassword, trigger: 'change'}
                    ],
                },
                rules:{
                    name: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    saleser: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    password: [
                        {required: true, validator: this.checkLoginPassword,trigger: 'blur'}
                    ],
                    mobile: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    // avatar: [
                    //     {required: true, message: 'please input',trigger: 'blur'}
                    // ],
                    email: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    wages: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    worker_life: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    workers: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    passport: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    gj: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    // age: [
                    //     {required: true, message: 'please input',trigger: 'blur'}
                    // ],
                    birthday: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    idcard_validity: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    idcard_no: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    card_pass: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    card_no: [
                        {required: true, message: 'please input',trigger: 'blur'}
                    ],
                    // is_active: [
                    //     {required: true, message: '请选择是否启用',trigger: 'blur'}
                    // ]
                },
                rolelist:[],
                options:[],
                adminList:[],
                cardReader:null,
                icdev: 0, //设备句柄
                snra:null, //typeA返回的序列号
                snra2:null, //typeA二次防冲突返回的序列号
                snrb:null, //typeB返回的序列号
                counter:null,
                icode2Uid:null,
                uhfEpc:null,
                is_usb_connected:false,
                dialogSetPassVisible:false,
                dialogBindCardVisible:false,
                cardSearchDialogShow:false,
            }
        },
        unmounted() {
            // this.disconnectReader()
            // this.cardReader = null
        },
        mounted(){
            // //回调, ret 包含三个属性, functionId, result 及 resultData.
            // //functionId 为函数 id (READER_CMD 中的属性), 确定返回的是哪个命令执行的结果
            // //result 为执行结果, 0 成功, 其它失败,
            // //当 result 为 0 时 resultData 为返回结果, 当 result 不为 0 时, resultData 为错误信息
            // this.cardReader = getReader()
            // var vm = this
            // this.cardReader.onResult(function (ret) {
            //     console.log(ret)
            //     //执行成功
            //     if (ret.result == 0) {
            //         switch (ret.functionId) {
            //             case READER_CMD._reader_server_connect:
            //                 // vm.$message.success('cardReader 读写器ws服务连接成功');
            //                 ElNotification({
            //                     title: 'Service notification',
            //                     message: "Card reader WebSocket service connected successfully",
            //                     position: 'top-right',
            //                     type: 'success',
            //                   })
            //                 vm.connectUsbReader()
            //                 break;
            //             case READER_CMD._reader_cmd_connect:
            //                 vm.icdev = parseInt(ret.resultData); //连接成功后, resultData 为设备句柄
            //                 if(!vm.is_usb_connected){
            //                     ElNotification({
            //                         title: 'Card reader prompt',
            //                         message: "cardReader USB card reader connected successfully",
            //                         position: 'top-right',
            //                         type: 'success',
            //                     })
            //                     // vm.$message.success('cardReader usb读写器连接成功')
            //                     vm.is_usb_connected = true
            //                 }
            //                 break;
            //             case READER_CMD._reader_cmd_disconnect:
            //                 // vm.$message.warning('cardReader usb读写器断开连接成功')
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read:
            //                 msg.value = msg.value + "SLE4442 卡读数据成功. 读取到的数据: " + ret.resultData + "\n";
            //                 break;
            //             case READER_CMD._reader_cmd_4442_write:
            //                 // vm.$message.success("卡写数据成功")
            //                 ElNotification({
            //                     title: 'card notification',
            //                     message: "card write success",
            //                     position: 'top-right',
            //                     type: 'success',
            //                 })
            //                 vm.formData.card_no = deepClone(vm.formDataBindCard.card_no)
            //                 vm.formData.card_pass = deepClone(vm.formDataBindCard.card_pass)
            //                 vm.handleBindCardClose()
            //                 break;
            //             case READER_CMD._reader_cmd_4442_verify_sc:
            //                 vm.sle4442Write(vm.formDataBindCard.card_no)
            //                 ElNotification({
            //                     title: 'card notification',
            //                     message: "card verify passwrod success",
            //                     position: 'top-right',
            //                     type: 'success',
            //                 })
            //                 // vm.$message.success("卡验证密码成功")
            //                 break;
            //             case READER_CMD._reader_cmd_4442_change_sc:
            //                 vm.$message.success("card change passwrod success")
            //                 vm.formData.card_pass = deepClone(vm.formDataPass.newCardPass)
            //                 vm.handleResetPassClose()
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_sc:
            //                 vm.$message.success("SLE4442 卡读取密码成功. 读取到的密码: " + ret.resultData)
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_counter:
            //                 vm.$message.success("SLE4442 卡读取密码错误计数成功. 密码错误计数为: " + parseInt(ret.resultData))
            //                 break;
            //             case READER_CMD._reader_cmd_4442_read_pro_bit:
            //                 vm.$message.success("SLE4442 卡读保护位成功. 读取到保护位数据: " + ret.resultData)
            //                 break;
            //             case READER_CMD._reader_cmd_4442_protect:
            //                 vm.$message.success("SLE4442 卡保护数据成功")
            //                 break;
            //         }
            //     }else {
            //         //失败打印错误信息
            //         if (ret.resultData != "" && ret.resultData != null && ret.resultData != undefined &&!!ret.resultData){
            //             if(ret.resultData === "初始化通信端口失败。"){
            //                 vm.$message.warning("Failed to initialize communication port")
            //             }else{
            //                 vm.$message.warning(ret.resultData)
            //             }
            //         }
            //         console.log(ret)
            //     }
            // })
        },
        methods:{
            handleClose() {
                this.dialogVisible=false
                this.loadingSave=false
                this.customCardNo=""
                this.formData = {
                    saleser:'',
                    username:'',
                    nickname:'',
                    mobile:'',
                    is_active:true,
                    avatar:'',
                    gender:0,
                }
                this.is_usb_connected = false
                this.cardSearchDialogShow = false
                //关闭读卡器
                this.disconnectReader()
                this.cardReader.CloseWS()
                this.cardReader = null
            },
            getcardno(cardno){
                // if(cardno === this.card_no){
                //     this.submitData()
                // }else{
                //     this.$message.warning("Card Number Error")
                // }
                let param = {
                    ...this.formData
                }
                if(this.formData.id){
                    this.loadingSave=true
                    UsersUsersEdit(param).then(res=>{
                        this.loadingSave=false
                        if(res.code ==2000) {
                            this.$message.success(res.msg)
                            this.handleClose()
                            this.$emit('refreshData')
                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                } 
            },
            cardVerify(){
                this.cardSearchDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.cardSearchFlag.addUserFn(null,"Card Verify")
                })
            },
            // 格式化卡号暂时，每4位加-
            formatCardNumber (cardNum) {
                // 获取input的dom对象，这里因为用的是element ui的input，所以需要这样拿到
                const input = this.$refs.cardInput.$el.getElementsByTagName('input')[0]
                // 获取当前光标的位置
                const cursorIndex = input.selectionStart
                // 字符串中光标之前-的个数
                const lineNumOfCursorLeft = (cardNum.slice(0, cursorIndex).match(/-/g) || []).length
                // 去掉所有-的字符串
                const noLine = cardNum.replace(/-/g, '')
                // 去除格式不对的字符并重新插入-的字符串
                const newCardNum = noLine.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')
                // 改后字符串中原光标之前-的个数
                const newLineNumOfCursorLeft = (newCardNum.slice(0, cursorIndex).match(/-/g) || []).length
                // 光标在改后字符串中应在的位置
                const newCursorIndex = cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft
                // 赋新值，nextTick保证-不能手动输入或删除，只能按照规则自动填入
                this.$nextTick(() => {
                    this.customCardNo = newCardNum
                    // 修正光标位置，nextTick保证在渲染新值后定位光标
                    this.$nextTick(() => {
                        // selectionStart、selectionEnd分别代表选择一段文本时的开头和结尾位置
                        input.selectionStart = newCursorIndex
                        input.selectionEnd = newCursorIndex
                    })
                })
            },
            // 格式化卡号暂时，每4位加-
            formatCardNumberLinkNew (cardNum) {
                // 获取input的dom对象，这里因为用的是element ui的input，所以需要这样拿到
                const input = this.$refs.cardLinkNewInput.$el.getElementsByTagName('input')[0]
                // 获取当前光标的位置
                const cursorIndex = input.selectionStart
                // 字符串中光标之前-的个数
                const lineNumOfCursorLeft = (cardNum.slice(0, cursorIndex).match(/-/g) || []).length
                // 去掉所有-的字符串
                const noLine = cardNum.replace(/-/g, '')
                // 去除格式不对的字符并重新插入-的字符串
                const newCardNum = noLine.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')
                // 改后字符串中原光标之前-的个数
                const newLineNumOfCursorLeft = (newCardNum.slice(0, cursorIndex).match(/-/g) || []).length
                // 光标在改后字符串中应在的位置
                const newCursorIndex = cursorIndex + newLineNumOfCursorLeft - lineNumOfCursorLeft
                // 赋新值，nextTick保证-不能手动输入或删除，只能按照规则自动填入
                this.$nextTick(() => {
                    this.customLinkNewCardNo = newCardNum
                    // 修正光标位置，nextTick保证在渲染新值后定位光标
                    this.$nextTick(() => {
                        // selectionStart、selectionEnd分别代表选择一段文本时的开头和结尾位置
                        input.selectionStart = newCursorIndex
                        input.selectionEnd = newCursorIndex
                    })
                })
            },
            formatNewCardNumber(cardNum){
                // 去掉所有-的字符串
                const noLine = cardNum.replace(/-/g, '')
                // 去除格式不对的字符并重新插入-的字符串
                const newCardNum = noLine.replace(/\D+/g, '').replace(/(\d{4})/g, '$1-').replace(/-$/, '')
                return newCardNum
            },
            calcAge(e){
                let birthdays = new Date(e.replace(/-/g, "/"));
                let d = new Date();
                let age =d.getFullYear() -birthdays.getFullYear() -(d.getMonth() < birthdays.getMonth() ||(d.getMonth() == birthdays.getMonth() &&d.getDate() < birthdays.getDate())? 1: 0);
                this.formData.age = age
            },
            handleBindCardClose(){
                this.dialogBindCardVisible = false
                this.formDataBindCard.card_no = ""
                this.formDataBindCard.card_pass = "ffffff"
            },
            openBindCardDialog(){
                this.dialogBindCardVisible = true
                this.customLinkNewCardNo = ""
                this.formDataBindCard.card_no = ""
                // this.formDataBindCard.card_no = generateCardNo()
            },
            submitBindCard() {
                this.$refs['rulesFormBindCard'].validate(obj=>{
                    if(obj) {
                        this.sle4442VerifySC(this.formDataBindCard.card_pass)
                    }
                })
            },
            sle4442VerifySC(pass) {
                try {
                    this.cardReader.sle4442VerifySC(this.icdev, pass);
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
            sle4442Write(cardno) {
                try {
                    var s = cardno.toString(16);
                    this.cardReader.sle4442Write(this.icdev, 32, s);
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
            handleResetPassClose(){
                this.dialogSetPassVisible = false
                this.formDataPass.newCardPass = "123456"
            },
            openRestPassDialog(){
                this.dialogSetPassVisible = true
                this.formDataPass.newCardPass = "123456"
            },
            checkResetPassword(rule, value, callback) {
                if (!value) {
                    return callback('password required')
                }
                if (value.length < 6) {
                    return callback('Password should not be less than 6 digits')
                }
                if (value.length > 6) {
                    return callback('Password not greater than 6 digits')
                }
                var numReg = /^[0-9]*$/
                var numRe = new RegExp(numReg)
                if (!numRe.test(value)) {
                    return callback('Must be a number from 0 to 9')
                }
                return callback()
            },
            checkLoginPassword(rule, value, callback) {
                if (!value) {
                    return callback('password required')
                }
                if (value.length < 8) {
                    return callback('Password should not be less than 8 digits')
                }
                if (value.length > 20) {
                    return callback('Password should not exceed 20 digits')
                }
                var numReg = /^[0-9A-Za-z]*$/
                var numRe = new RegExp(numReg)
                if (!numRe.test(value)) {
                    return callback('Must be a combination of numbers or letters')
                }
                return callback()
            },
            submitResetPassword() {
                this.$refs['rulesFormResetPass'].validate(obj=>{
                    if(obj) {
                        this.sle4442ChangeSC(this.formDataPass.newCardPass)
                    }
                })
            },
            //断开连接的读写器
            disconnectReader() {
                try {
                    this.cardReader.disconnect(this.icdev);
                } catch (e) {
                    let stre = JSON.stringify(e)
                    if(stre.indexOf("createSocket is not defined") != -1 || !!stre||!(!!e.Message)){
                        this.$message.warning("Card Server not connected.")
                    }else{
                        this.$message.warning(e.Message)
                    }
                }
            },
            getAdminUser(){
                apiSystemUser({page:1,limit:999}).then(res=>{
                    if(res.code === 2000){
                        this.adminList = res.data.data
                    }
                })
            },
            addUserFn(item,flag) {
                this.loadingTitle=flag
                this.dialogVisible=true
                if(item){
                    // delete this.rules.password
                    this.formData = deepClone(item)
                    this.customCardNo = this.formatNewCardNumber(this.formData.card_no)
                }else{
                    this.formData.saleser = getStorage("userId")
                }
                this.getAdminUser()
                //回调, ret 包含三个属性, functionId, result 及 resultData.
                //functionId 为函数 id (READER_CMD 中的属性), 确定返回的是哪个命令执行的结果
                //result 为执行结果, 0 成功, 其它失败,
                //当 result 为 0 时 resultData 为返回结果, 当 result 不为 0 时, resultData 为错误信息
                this.cardReader = getReader()
                var vm = this
                this.cardReader.onResult(function (ret) {
                    // console.log(ret)
                    //执行成功
                    if (ret.result == 0) {
                        switch (ret.functionId) {
                            case READER_CMD._reader_server_connect:
                                // vm.$message.success('cardReader 读写器ws服务连接成功');
                                ElNotification({
                                    title: 'Service notification',
                                    message: "Card reader WebSocket service connected successfully",
                                    position: 'top-right',
                                    type: 'success',
                                })
                                vm.connectUsbReader()
                                break;
                            case READER_CMD._reader_cmd_connect:
                                vm.icdev = parseInt(ret.resultData); //连接成功后, resultData 为设备句柄
                                if(!vm.is_usb_connected){
                                    ElNotification({
                                        title: 'Card reader prompt',
                                        message: "cardReader USB card reader connected successfully",
                                        position: 'top-right',
                                        type: 'success',
                                    })
                                    // vm.$message.success('cardReader usb读写器连接成功')
                                    vm.is_usb_connected = true
                                }
                                break;
                            case READER_CMD._reader_cmd_disconnect:
                                // vm.$message.warning('cardReader usb读写器断开连接成功')
                                break;
                            case READER_CMD._reader_cmd_4442_read:
                                // msg.value = msg.value + "SLE4442 卡读数据成功. 读取到的数据: " + ret.resultData + "\n";
                                break;
                            case READER_CMD._reader_cmd_4442_write:
                                // vm.$message.success("卡写数据成功")
                                ElNotification({
                                    title: 'card notification',
                                    message: "card write success",
                                    position: 'top-right',
                                    type: 'success',
                                })
                                vm.formData.card_no = deepClone(vm.formDataBindCard.card_no)
                                vm.formData.card_pass = deepClone(vm.formDataBindCard.card_pass)
                                vm.handleBindCardClose()
                                break;
                            case READER_CMD._reader_cmd_4442_verify_sc:
                                vm.sle4442Write(vm.formDataBindCard.card_no)
                                ElNotification({
                                    title: 'card notification',
                                    message: "card verify passwrod success",
                                    position: 'top-right',
                                    type: 'success',
                                })
                                // vm.$message.success("卡验证密码成功")
                                break;
                            case READER_CMD._reader_cmd_4442_change_sc:
                                vm.$message.success("card change passwrod success")
                                vm.formData.card_pass = deepClone(vm.formDataPass.newCardPass)
                                vm.handleResetPassClose()
                                break;
                            case READER_CMD._reader_cmd_4442_read_sc:
                                vm.$message.success("SLE4442 卡读取密码成功. 读取到的密码: " + ret.resultData)
                                break;
                            case READER_CMD._reader_cmd_4442_read_counter:
                                vm.$message.success("SLE4442 卡读取密码错误计数成功. 密码错误计数为: " + parseInt(ret.resultData))
                                break;
                            case READER_CMD._reader_cmd_4442_read_pro_bit:
                                vm.$message.success("SLE4442 卡读保护位成功. 读取到保护位数据: " + ret.resultData)
                                break;
                            case READER_CMD._reader_cmd_4442_protect:
                                vm.$message.success("SLE4442 卡保护数据成功")
                                break;
                        }
                    }else {
                        //失败打印错误信息
                        if (ret.resultData != "" && ret.resultData != null && ret.resultData != undefined &&!!ret.resultData){
                            if(ret.resultData === "初始化通信端口失败。"){
                                vm.$message.warning("Failed to initialize communication port")
                            }else if(ret.resultData === "无效的句柄。"){
                                vm.$message.warning("invalid handle")
                            }else if(ret.resultData === "设备未连接。"){
                                vm.$message.warning("card devices not connected")
                            }
                            else{
                                vm.$message.warning(ret.resultData)
                            }
                        }
                        console.log(ret)
                    }
                })
            },
            //连接USB读写器
            connectUsbReader() {
                try {
                   this.cardReader.connectUsb(); //连接usb设备
                } catch (e) {
                    let stre = JSON.stringify(e)
                    if(stre.indexOf("createSocket is not defined") != -1 || !!stre ||!(!!e.Message)){
                        this.$message.warning("Card Server not connected.")
                    }else{
                        this.$message.warning(e.Message)
                    }
                }
            },
            //更改卡密码
            sle4442ChangeSC(pass) {
                try {
                    this.cardReader.sle4442ChangeSC(this.icdev, pass);
                    // this.formData.card_pass = pass
                } catch (e) {
                    this.$message.warning(e.Message)
                }
            },
            submitData() {
                this.$refs['rulesForm'].validate(obj=>{
                    if(obj) {
                        var roleids = getStorage("role_ids")
                        var identity = getStorage("identity")
                        let param = {
                            ...this.formData
                        }
                        // param.role = param.role?param.role.split(" "):[]
                        if(this.formData.id){
                            if(identity == 0 ||!roleids.includes("public")){
                                this.loadingSave=true
                                UsersUsersEdit(param).then(res=>{
                                    this.loadingSave=false
                                    if(res.code ==2000) {
                                        this.$message.success(res.msg)
                                        this.handleClose()
                                        this.$emit('refreshData')
                                    } else {
                                        this.$message.warning(res.msg)
                                    }
                                })
                            }else{
                                // this.is_usb_connected = false
                                // //关闭读卡器
                                // this.disconnectReader()
                                // this.cardReader.CloseWS()
                                this.cardReader = null
                                this.cardVerify()
                            }
                            
                        }else{
                            this.loadingSave=true
                            UsersUsersAdd(param).then(res=>{
                                this.loadingSave=false
                                if(res.code ==2000) {
                                    this.$message.success(res.msg)
                                    this.handleClose()
                                    this.$emit('refreshData')
                                } else {
                                    this.$message.warning(res.msg)
                                }
                            })
                        }

                    }
                })
            },
            imgBeforeUpload(file) {
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('图片只能是 JPG/PNG 格式!');
                    return false
                }
                return isJPG;
            },
            async imgUploadRequest(param) {
                var vm = this
                let obj= await platformsettingsUploadPlatformImg(param)
                if(obj.code == 2000) {
                    let res=''
                    if (obj.data.data[0].indexOf("://")>=0){
                        res = obj.data.data[0]

                    }else{
                        res = url.split('/api')[0]+obj.data.data[0]
                    }
                    vm.formData.avatar = res
                } else {
                    vm.$message.warning(res.msg)
                }
            },
            imgUploadSuccess() {
                this.$refs.uploadDefaultImage.clearFiles()
            }
        }
    }
</script>
<style scoped>
    .avatar-uploader .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 128px;
      height: 128px;
      line-height: 128px;
      text-align: center;
    }
    .avatar {
      width: 128px;
      height: 128px;
      display: block;
    }
</style>

