<template>
    <div :class="{'ly-is-full':isFull}">
        <div class="tableSelect" ref="tableSelect">
            <el-form :inline="true" :model="formInline" label-position="left">
                <el-form-item :label="$t('userManage.name3')">
                    <el-input size="default" v-model.trim="formInline.mobile" maxlength="60"  clearable :placeholder="$t('userManage.name3')" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('userManage.name41')">
                    <el-input size="default" v-model.trim="formInline.card_no" maxlength="60"  clearable :placeholder="$t('userManage.name41')" style="width:180px"></el-input>
                </el-form-item>
                <el-form-item :label="$t('userManage.name2')">
                    <el-input size="default" v-model.trim="formInline.name" maxlength="60"  clearable :placeholder="$t('userManage.name2')" style="width:180px"></el-input>
                </el-form-item>
<!--                <el-form-item label="创建时间：">-->
<!--                    <el-date-picker-->
<!--                            style="width:350px"-->
<!--                            v-model="timers"-->
<!--                            type="datetimerange"-->
<!--                            @change="timeChange"-->
<!--                            range-separator="至"-->
<!--                            start-placeholder="开始日期"-->
<!--                            end-placeholder="结束日期">-->
<!--                    </el-date-picker>-->
<!--                </el-form-item>-->
                <el-form-item label=""><el-button  @click="search" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'Search')">{{$t('btn.search')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="cardsearch" type="primary" icon="Search" v-show="hasPermission(this.$route.name,'CardSearch')">{{$t('btn.cardSearch')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="handleEdit('','reset')" icon="Refresh">{{$t('btn.reset')}}</el-button></el-form-item>
                <el-form-item label=""><el-button  @click="addAdmin" type="primary" icon="Plus" v-show="hasPermission(this.$route.name,'Create')">{{$t('userManage.name51')}}</el-button></el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-table  :height="'calc('+(tableHeight)+'px)'" border :data="tableData" ref="tableref" v-loading="loadingPage" style="width: 100%">
                <el-table-column type="index" width="70" align="center" :label="$t('userManage.name0')">
                    <template #default="scope">
                        <span v-text="getIndex(scope.$index)"></span>
                    </template>
                </el-table-column>
                <el-table-column min-width="80" prop="avatar" :label="$t('userManage.name1')">
                    <template #default="scope">
                        <el-image  :src="scope.row.userinfo.avatar ? scope.row.userinfo.avatar : defaultImg" :preview-src-list="[scope.row.userinfo.avatar]"  :preview-teleported="true" style="width: 40px;height: 40px" :onerror="defaultImg" ></el-image>
                    </template>
                </el-table-column>
                <el-table-column min-width="110" prop="name" :label="$t('userManage.name2')"></el-table-column>
                <el-table-column min-width="140" prop="mobile" :label="$t('userManage.name3')"></el-table-column>
                <el-table-column min-width="60" prop="age" :label="$t('userManage.name39')"></el-table-column>
                <el-table-column min-width="90" prop="gendername" :label="$t('adminManage.gender')"></el-table-column>
                <el-table-column min-width="120" prop="gj" :label="$t('userManage.name301')"></el-table-column>
                <el-table-column min-width="130" prop="saleser_name" :label="$t('userManage.name4')"></el-table-column>
                <el-table-column min-width="100" :label="$t('userManage.name45')">
                    <template #default="scope">
<!--                        <el-tag v-if="scope.row.is_active">正常</el-tag>-->
<!--                        <el-tag v-else type="danger">禁用</el-tag>-->
                        <el-switch
                            v-model="scope.row.userinfo.is_active"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            :active-text="$t('btn.nomal')"
                            :inactive-text="$t('btn.disable')"
                            inline-prompt
                            :disabled="!hasPermission(this.$route.name,'Disable')"
                            @change="changeStatus(scope.row)">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column min-width="170" prop="create_datetime" :label="$t('userManage.name46')"></el-table-column>
                <el-table-column min-width="180" prop="card_no" :label="$t('userManage.name41')"></el-table-column>
                <el-table-column min-width="120" prop="userinfo.balance" :label="$t('userManage.name42')"></el-table-column>
                <el-table-column min-width="120" prop="userinfo.r_amount" :label="$t('userManage.name43')"></el-table-column>
                <el-table-column min-width="110" prop="userinfo.s_amount" :label="$t('userManage.name44')"></el-table-column>
                <el-table-column min-width="110" prop="userinfo.sd_amount" :label="$t('userManage.yzsamount')"></el-table-column>
                <el-table-column min-width="140" :label="$t('userManage.dzsamount')">
                    <template #default="scope">
                        {{(parseFloat(scope.row.userinfo.s_amount) - parseFloat(scope.row.userinfo.sd_amount)).toFixed(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="350">
                    <template #header>
                        <div style="display: flex;justify-content: space-between;align-items: center;">
                            <div>{{$t('btn.oprate')}}</div>
                            <div @click="setFull">
                                <el-tooltip :content="$t('btn.fullscreen')" placement="bottom">
                                    <el-icon ><full-screen /></el-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </template>
                    <template #default="scope">
<!--                        <el-button-group>-->
<!--                            <el-button text type="primary" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">编辑会员卡</el-button>-->
<!--                            <el-button text type="primary" >添加子账户</el-button>-->
<!--                            <el-button text type="primary" >清除子账户</el-button>-->
<!--                            <el-button text type="primary" @click="handleEdit(scope.row,'delete')" v-show="hasPermission(this.$route.name,'Delete')">删除会员卡</el-button>-->
<!--&lt;!&ndash;                            <el-button text type="primary" @click="handleEdit(scope.row,'disable')" v-show="hasPermission(this.$route.name,'Update')">{{scope.row.is_active?'禁用':'启用'}}</el-button>&ndash;&gt;-->
<!--                        </el-button-group>-->
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'edit')" v-show="hasPermission(this.$route.name,'Update')">{{$t('userManage.name47')}}</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'addzi')" v-show="hasPermission(this.$route.name,'AddZiAcount')">{{$t('userManage.name48')}}</span>
                        <span class="table-operate-btn" @click="handleEdit(scope.row,'clearzi')" v-show="hasPermission(this.$route.name,'ClearZiAcount')">{{$t('userManage.name49')}}</span>
                        <span class="table-operate-btn" @click="deleteCardVerify(scope.row)" v-show="hasPermission(this.$route.name,'Delete')">{{$t('userManage.name50')}}</span>
<!--                        <span class="table-operate-btn" >-->
<!--                            <span v-if="scope.row.is_active">禁用</span>-->
<!--                            <span v-else>启用</span>-->
<!--                        </span>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
        <add-user ref="addUserFlag" @refreshData="getData" v-if="userInfoDialogShow"></add-user>
        <AddModuleCardSearch ref="cardSearchFlag" @refreshData="getDataCardSearch" v-if="cardSearchDialogShow" @closed="cardSearchDialogShow=false"></AddModuleCardSearch>
        <AddZiAcount ref="addZIAcountFlag" @refreshData="getDataCardSearchOrGetData"></AddZiAcount>
        <ClearZiAcount ref="clearZIAcountFlag" @refreshData="getDataCardSearchOrGetData"></ClearZiAcount>
        <AddModuleDeleteVerify ref="deleteCardVerifyFlag" @refreshData="handelVerifyDelete" v-if="deleteVerifyDialogShow" @closed="deleteVerifyDialogShow=false"></AddModuleDeleteVerify>
    </div>
</template>
<script>
    import addUser from "./components/addUser";
    import AddModuleCardSearch from "./components/addModuleCardSearch";
    import AddModuleDeleteVerify from "./components/addModuleDeleteVerify";
    import Pagination from "@/components/Pagination";
    import {dateFormats,getTableHeight,hasPermission} from "@/utils/util";
    import {superOerateSet, UsersUsers, UsersUsersDelete, UsersUsersdisableEdit, UsersUsersExportexecl,UsersCardSearchList} from '@/api/api'
    import AddZiAcount from "@/views/userManage/components/addZiAcount.vue";
    import ClearZiAcount from "@/views/userManage/components/clearZiAcount.vue";
    export default {
        components:{
            Pagination,
            addUser,
            AddZiAcount,
            ClearZiAcount,
            AddModuleCardSearch,
            AddModuleDeleteVerify
        },
        name:'userManage',
        data() {
            return {
                isFull:false,
                tableHeight:500,
                loadingPage:false,
                formInline:{
                    page: 1,
                    limit: 10,
                },
                defaultImg:require('../../assets/img/agentavatar.png'),
                pageparm: {
                    page: 1,
                    limit: 10,
                    total: 0
                },
                statusList:[
                    {id:1,name:'nomal'},
                    {id:0,name:'disable'}
                ],
                identityList:[
                    {id:0,name:'普通用户'},
                    {id:1,name:'会员'},
                ],
                timers:[],
                tableData:[],
                userInfoDialogShow:false,
                cardSearchDialogShow:false,
                deleteVerifyDialogShow:false,
            }
        },

        methods:{
            // 表格序列号
            getIndex($index) {
                // (当前页 - 1) * 当前显示数据条数 + 当前行数据的索引 + 1
                return (this.pageparm.page-1)*this.pageparm.limit + $index +1
            },
            setFull(){
                this.isFull=!this.isFull
                window.dispatchEvent(new Event('resize'))
            },
            changeStatus(row) {
                let vm = this
                let flat = row.userinfo.is_active
                row.userinfo.is_active = !flat
                vm.$confirm('are you sure to change the status？','tip',{
                    closeOnClickModal:false,
                    type:"warning"
                }).then(res=>{
                    UsersUsersdisableEdit({id:row.user}).then(res=>{
                        if(res.code == 2000) {
                            vm.is_active = flat
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            flat ? row.userinfo.is_active = false : row.userinfo.is_active = true
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{

                })
            },
            addAdmin() {
                this.userInfoDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.addUserFlag.addUserFn(null,this.$t('btn.add'))
                })
            },
            cardsearch(){
                this.cardSearchDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.cardSearchFlag.addUserFn(null,this.$t('btn.cardSearch'))
                })
            },
            handleEdit(row,flag) {
                if(flag=='edit') {
                    this.userInfoDialogShow = true
                    this.$nextTick(()=>{
                        this.$refs.addUserFlag.addUserFn(row,this.$t('btn.edit'))
                    })
                }
                else if(flag=='addzi') {
                    this.userInfoDialogShow = true
                    this.$nextTick(()=>{
                        this.$refs.addZIAcountFlag.addUserFn(row,this.$t('userManage.name48'))
                    })
                }
                else if(flag=='clearzi') {
                    this.$refs.clearZIAcountFlag.addUserFn(row,this.$t('userManage.name49'))
                }
                else if(flag=='disable'){
                    let vm = this
                    UsersUsersdisableEdit({id:row.id}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }
                else if(flag=='delete') {
                    let vm = this
                    vm.$confirm('are you sure to delete the data?','tip',{
                        closeOnClickModal:false,
                        type:"warning"
                    }).then(res=>{
                        UsersUsersDelete({id:row.id}).then(res=>{
                            if(res.code == 2000) {
                                vm.$message.success(res.msg)
                                vm.getData()
                            } else {
                                vm.$message.warning(res.msg)
                            }
                        })
                    }).catch(()=>{

                    })
                }
                else if(flag=="reset"){
                    this.formInline = {
                        page:1,
                        limit: 10
                    }
                    this.pageparm={
                        page: 1,
                        limit: 10,
                        total: 0
                    }
                    this.getData()
                }
            },
            /**
             * 从URL里下载文件
            */
            // 下载文件
            downloadFileURL(url) {
                var iframe =document.createElement("iframe")
                iframe.style.display ="none";
                iframe.src = url;
                document.body.appendChild(iframe);
            },
            exportDataBackend() {
                var params = {
                    page: 1,
                    limit: 9999,
                }
                UsersUsersExportexecl(params).then(res => {
                     if(res.code ==2000) {
                         this.downloadFileURL(res.data.data)
                         //this.$message.warning(res.data.data)
                     }
                 })
            },
            callFather(parm) {
                this.formInline.page = parm.page
                this.formInline.limit = parm.limit
                this.getData()
            },
            search() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.getData()
            },
            cardsearchData() {
                this.formInline.page = 1
                this.formInline.limit = 10
                this.cardSearchGetData()
            },
            getDataCardSearchOrGetData(){
                if(hasPermission(this.$route.name,'Search')){
                    this.getData()
                }else{
                    this.getDataCardSearch("")
                }
            },
            getDataCardSearch(item){
                if(!!item){
                    this.formInline.card_no = item
                }
                this.cardsearchData()
            },
            //获取列表
            async cardSearchGetData() {
                this.userInfoDialogShow = false
                this.loadingPage = true
                UsersCardSearchList(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }
                 })
            },
            deleteCardVerify(row){
                this.deleteVerifyDialogShow = true
                this.$nextTick(()=>{
                    this.$refs.deleteCardVerifyFlag.addUserFn(row,"Card Verify")
                })
            },
            handelVerifyDelete(row,card_no){
                let vm = this
                if(row.card_no != card_no){
                    this.$message.warning("Card Number Error")
                    return
                }
                vm.$confirm('are you sure to delete the data?','tip',{
                    closeOnClickModal:false,
                    type:"warning"
                }).then(res=>{
                    UsersUsersDelete({id:row.id}).then(res=>{
                        if(res.code == 2000) {
                            vm.$message.success(res.msg)
                            vm.getData()
                        } else {
                            vm.$message.warning(res.msg)
                        }
                    })
                }).catch(()=>{

                })
            },
            //获取列表
            async getData() {
                this.userInfoDialogShow = false
                this.loadingPage = true
                UsersUsers(this.formInline).then(res => {
                     this.loadingPage = false
                     if(res.code ==2000) {
                         this.tableData = res.data.data
                         this.pageparm.page = res.data.page;
                         this.pageparm.limit = res.data.limit;
                         this.pageparm.total = res.data.total;
                     }else{
                        this.tableData = []
                        this.pageparm.page = 1
                        this.pageparm.total = 0
                     }
                 })
            },

            timeChange(val){
                if (val) {
                    this.formInline.beginAt=dateFormats(val[0],'yyyy-MM-dd hh:mm:ss');
                    this.formInline.endAt=dateFormats(val[1],'yyyy-MM-dd hh:mm:ss');
                } else {
                    this.formInline.beginAt = null
                    this.formInline.endAt = null
                }
                this.search()
            },
            // 计算搜索栏的高度
            listenResize() {
                this.$nextTick(() => {
                    this.getTheTableHeight()
                })
              },
            getTheTableHeight(){
                let tabSelectHeight = this.$refs.tableSelect?this.$refs.tableSelect.offsetHeight:0
                tabSelectHeight = this.isFull?tabSelectHeight - 110:tabSelectHeight
                this.tableHeight =  getTableHeight(tabSelectHeight)
            }

        },
        created() {
            this.getData()
        },
        mounted() {
            // 监听页面宽度变化搜索框的高度
            window.addEventListener('resize', this.listenResize);
            this.$nextTick(() => {
              this.getTheTableHeight()
            })
        },

        unmounted() {
             // 页面销毁，去掉监听事件
			      window.removeEventListener("resize", this.listenResize);
        },
    }
</script>
